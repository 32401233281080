import cn from 'clsx';
import React from 'react';

import { DropListItemProps } from '@/shared/ui/drop-list-item/types';
import { Icons, Illustration } from '@/shared/ui/illustration/illustration';
import { Typography } from '@/shared/ui/typography';

import styles from './drop-list-item.module.scss';

export const DropListItem: React.FC<DropListItemProps> = ({
  variant = 'search',
  iconLeft,
  iconRight,
  title,
  onPress,
  className,
  isSelected,
  disabled,
  removable,
  onRemove,
}) => {
  const containerClasses = cn(
    styles.container,
    {
      [styles['disabled']]: disabled,
      [styles['selected']]: isSelected,
    },
    className,
  );

  const slotClasses = cn(
    styles['container-left-slot'],
    styles[`container-left-slot-${variant}`],
  );

  return (
    <div className={containerClasses} onClick={onPress}>
      <div className={slotClasses}>
        {iconLeft}
        <Typography
          id={'list-item-title'}
          className={styles['container-drop_item']}
        >
          {title}
        </Typography>
        {iconRight}
      </div>
      {isSelected && (
        <Illustration
          style={{ color: '#6941C6' }}
          name={'check'}
          spanTagClassName={styles['check-icon']}
          spriteName={'icons'}
        />
      )}
      {removable && (
        <Illustration
          style={{ color: '#8F8F8F' }}
          name={'x-close'}
          spanTagClassName={styles['close-icon']}
          spriteName={'icons'}
          onClick={
            onRemove
              ? e => {
                  e.stopPropagation();
                  onRemove();
                }
              : undefined
          }
        />
      )}
    </div>
  );
};
