import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { useStore } from '@/store/context';

export const WithCurrency = observer(
  ({ children }: { children: ReactNode }) => {
    const currencySign = useStore().app.getCurrencySign;

    return `${currencySign}${children}`;
  },
);
